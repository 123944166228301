/******* Bootstrap Theming ********/

$primary: #DA9F5B;
$secondary: #33211D;
$light: #FFFBF2;
$dark: #111111;

$font-family-sans-serif: 'Mulish', sans-serif;

$headings-font-family: 'Mulish', sans-serif;

$paragraph-font-family: "Mulish";

$main-color: #1c5ab6;
$white-color: #ffffff;
$black-color: #000000;
$paragraph-color: #646464;
$font-size: 16px;
$transition: 0.5s;


$body-bg: $light;

$body-color: #555555;

$headings-color: $secondary;

$display1-size: 8rem;

$enable-responsive-font-sizes: true;

$enable-rounded: false;

$enable-shadows: false;

@import "bootstrap/scss/bootstrap";

// Animation css
// @import '~animate.css/animate.min.css';

// Bootstrap Layout scss
// @import '/node_modules/bootstrap/scss/bootstrap.scss';

// // Owl Carousel
// @import '/node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
// @import '/node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

// Themify icon scss
@import 'themify';

// Theme Scss
// @import 'theme/variables';
// :root {
//   --theme-deafult: #e1b0a3;
//   // This gradient color only for gym and marijuana layout
//   --theme-gradient1: #01effc;
//   --theme-gradient2: #485ff2;
// }
// @import 'theme/style';
// @import 'theme/theme-dark';
// @import 'theme/menu';
// @import 'theme/responsive';


h5 {
  font-family: $font-family-sans-serif;
}


/********** Custom CSS ************/
h1,
h2,
.font-weight-bold {
  font-weight: 700 !important;
}

h3,
h4,
.font-weight-medium {
  font-weight: 500 !important;
}

h5,
h6,
.font-weight-normal {
  font-weight: 400 !important;
}

.btn-square {
  width: 36px;
  height: 36px;
}

.btn-sm-square {
  width: 28px;
  height: 28px;
}

.btn-lg-square {
  width: 46px;
  height: 46px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 30px;
  z-index: 11;
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
}

@-webkit-keyframes action {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-15px);
  }
}

@keyframes action {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-15px);
  }
}

.container-fluid.nav-bar {
  position: absolute;
  z-index: 9;
}

@media (max-width: 991.98px) {
  .container-fluid.nav-bar {
    position: relative;
    background: $secondary;
  }
}

.navbar-dark .navbar-nav .nav-link {
  padding-left: 15px;
  padding-right: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  color: $light;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  color: $primary;
}

.overlay-top::before,
.overlay-bottom::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 15px;
  left: 0;
  z-index: 1;
}

/*================================================
Navbar Area CSS
=================================================*/
.navbar {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 22px;
  letter-spacing: 1px;
  cursor: pointer;

  .navbar-nav {
    margin: auto;

    .nav-item {
      padding-right: 15px;
      color: white;
      font-size: 20px;
      font-weight: 200;
      font-family: "Carter One", system-ui;
    }
  }

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 5px 08px 0 #1447c0;
    background-color: #336ca8 !important;
    color: $white-color;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    border-bottom: #1447c0 1px solid;

    .logo {
      img {
        width: 200px;
        margin-left: 30px;
      }
    }

    .navbar-nav {
      margin: auto;

      .nav-item {
        padding-right: 15px;

        a {
          color: $white-color;
          font-weight: bolder;
        }
      }
    }
  }

  .container-fluid {
    max-width: 1400px;
    margin: auto;
  }
}

.navbar-light {
  background-color: transparent !important;
  color: white;

  .logo {
    h3 {
      font-size: 35px;
      margin-bottom: 0;
    }
  }

  .navbar-nav {
    margin: auto;

    .nav-item {
      position: relative;
      padding: 0;

      span {
        display: block;
        font-size: 12px;
      }

      a {
        color: $white-color;
        // text-transform: capitalize;
        position: relative;

        font: {
          weight: 600;
          size: 18px;
        }

        padding: {
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }

        margin: {
          left: 14px;
          right: 14px;
        }

        &:hover,
        &:focus,
        &.active {
          color: $main-color;
          position: relative;
          transition: $transition;
        }

        i {
          font-size: 16px;
          position: relative;
          top: 2px;
          display: inline-block;
          margin-left: -4px;
          margin-right: -4px;
        }
      }

      &:last-child {
        a {
          margin-right: 0;
        }
      }

      &:first-child {
        a {
          margin-left: 0;
        }
      }

      &:hover,
      &.active {
        a {
          color: $main-color;
          transition: $transition;
        }
      }
    }
  }

  .others-option {
    .option-item {
      color: $black-color;
      display: inline-block;
      position: relative;
      line-height: 1;

      .search-btn {
        cursor: pointer;
        transition: $transition;
        color: $black-color;
        font-size: 20px;
        font-weight: 600;
      }

      .close-btn {
        cursor: pointer;
        display: none;
        transition: $transition;
        width: 20px;
        color: $black-color;
        font-size: 18px;

        &.active {
          display: block;
        }
      }
    }
  }
}


// .overlay-top::before {
//     top: -1px;
//     background: url(../img/overlay-top.png);
// }

// .overlay-bottom::after {
//     bottom: -1px;
//     background: url(../img/overlay-bottom.png);
// }

.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(51, 33, 29, .7);
  z-index: 1;
}

.section-title {
  position: relative;
  padding: 115px 0 35px 0;
  text-align: center;
}

.section-title::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 100px;
  top: 0;
  left: 50%;
  margin-left: 1px;
  background: $primary;
}

.service-icon {
  margin-right: 15px;
  width: 45px;
  height: 45px;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 45px;
  color: $secondary;
  background: $primary;
}

.menu-price {
  position: absolute;
  margin: 0;
  top: -5px;
  right: 10px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 45px;
  color: $secondary;
  background: $primary;
}

.testimonial-carousel .owl-dots {
  margin-top: 30px;
  text-align: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: $primary;
  transition: .5s;
}

.testimonial-carousel .owl-dot.active {
  width: 40px;
  background: $secondary
}

.testimonial-carousel .owl-item img {
  width: 80px;
  height: 80px;
}

.contact-form .help-block ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.reservation,
.page-header,
.offer,
.footer {
  background: linear-gradient(rgba(51, 33, 29, .9), rgba(51, 33, 29, .9)), url(../images/banner6.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

/*================================================
Footer Area CSS
=================================================*/
.single-footer-widget {
  margin-bottom: 30px;

  .logo {
    display: inline-block;
    margin-bottom: 15px;

    h2 {
      margin-bottom: 0;
      font-size: 35px;
      font-weight: 700;
    }
  }

  p {
    margin-bottom: 0;
  }

  .social-list {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 16px;

    li {
      display: inline-block;
      list-style-type: none;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      display: inline-block;
      height: 35px;
      width: 35px;
      line-height: 38px;
      background-color: $main-color;
      color: $white-color;
      text-align: center;
      font-size: 16px;
      border-radius: 2px;
      border: 1px solid $main-color;

      &:hover {
        background-color: transparent;
        color: $black-color;
      }
    }
  }

  h3 {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 25px;
  }

  .list {
    padding-left: 0;
    margin-bottom: 0;

    li {
      margin-bottom: 8px;
      list-style-type: none;
      font-size: $font-size;
      font-family: $paragraph-font-family;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        display: block;
        color: #818992;
        font-weight: 500;
        transition: $transition;

        &:hover {
          color: $main-color;
          letter-spacing: 1px;
        }
      }
    }
  }

  .footer-holder {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;

    li {
      list-style-type: none;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

/*================================================
Copy Right CSS
=================================================*/
.copy-right {
  background-color: #fafafa;
  padding-top: 30px;
  padding-bottom: 30px;
}

.copy-right-content {
  text-align: center;

  p {
    font-size: $font-size;
    margin-bottom: 0;
    color: $black-color;
    font-weight: 600;

    a {
      font-size: 18px;
      font-weight: bold;
    }
  }
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  top: 0;
  right: 15px;
  color: $white-color;
  background-color: $main-color;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  opacity: 0;
  visibility: hidden;
  transition: 0.9s;
  border-radius: 50%;
  border: 1px solid $main-color;

  &.active {
    top: 98%;
    transform: translateY(-98%);
    opacity: 1;
    visibility: visible;
  }

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: $transition;
    font-size: 16px;

    &:last-child {
      opacity: 0;
      visibility: hidden;
      top: 60%;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: $black-color;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
    border-radius: 50%;
  }

  &:hover,
  &:focus {
    color: $white-color;

    &::before {
      opacity: 1;
      visibility: visible;
    }

    i {
      &:first-child {
        opacity: 0;
        top: 0;
        visibility: hidden;
      }

      &:last-child {
        opacity: 1;
        visibility: visible;
        top: 50%;
      }
    }
  }
}

.menuCambiado {
  right: 20px !important;
  position: absolute;
  padding: 20px;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255,255,255,0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-shopping-cart-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

@media (max-width: 1000px) {
  .menuCambiado {
    right: 20px !important;
    top: 80px;
    position: absolute;
    background-color: #fafafa;
    padding: 20px;
  }

  .navbar {
    &.is-sticky {
      .navbar-nav {
        .nav-item {
          a {
            color: $main-color;
          }
        }
      }
    }
  }

}

.fc-black {
  color: black;
}

.fc-white {
  color: white;
}

.fc-primary {
  color: #336ca8;
}

.fc-secondary {
  color: #C07220;
}

.fc-danger {
  color: crimson
}

.fc-warning {
  color: #DA9F5B;
}


.fs-10 {
  font-size: 10px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px;
}

.fs-28 {
  font-size: 28px;
}

.fs-32 {
  font-size: 32px;
}

.fs-36 {
  font-size: 36px;
}

.fs-40 {
  font-size: 40px;
}

.br-10 {
  border-radius: 10px;
}

.br-20 {
  border-radius: 20px;
}


.cu-pointer {
  cursor: pointer;
}
