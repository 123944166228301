// /* You can add global styles to this file, and also import other style files */
// /* You can add global styles to this file, and also import other style files */

@import 'ngx-toastr/toastr';

@import './../node_modules/aos/dist/aos.css';

@import "./assets/scss/style.scss";

@import './assets/css/whatsapp-chat-support.css';

$main-color: rgb(39, 71, 116);
$white-color: #ffffff;
$black-color: #000000;
$paragraph-color: #646464;
$font-size: 16px;
$transition: 0.5s;

body {
  overflow-x: hidden;
}

* {
  touch-action: manipulation;
}

/*================================================
Navbar Area CSS
=================================================*/
.navbar {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 20px;
  letter-spacing: 1px;
  color: rgb(58, 35, 141);

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0px 2px 28px 0px rgba(0, 0, 0, 0.09);
    // background-color: #fff !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    border-bottom: #fff 1px solid;
    color: #30b2c1;

    // .navbar-nav {
    //     margin: auto;

    //     .nav-item {
    //         a {
    //             color: $main-color;
    //         }
    //     }
    // }
  }

  .container-fluid {
    max-width: 1400px;
    margin: auto;
  }
}

.navbar-light {
  background-color: #0c2b5b !important;
  color: $white-color;

  .logo {
    h3 {
      font-size: 35px;
      margin-bottom: 0;
    }
  }

  .navbar-nav {
    margin: auto;

    .nav-item {
      position: relative;
      padding: 0;
      margin-right: 10px;

      span {
        display: block;
        font-size: 12px;
      }

      a {
        color: $white-color;
        // text-transform: capitalize;
        position: relative;

        font: {
          weight: 900;
          size: 20px;
        }

        padding: {
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }

        margin: {
          left: 14px;
          right: 24px;
        }

        &:hover,
        &:focus,
        &.active {
          color: $main-color;
          position: relative;
          transition: $transition;
        }

        i {
          font-size: 16px;
          position: relative;
          top: 2px;
          display: inline-block;
          margin-left: -4px;
          margin-right: -4px;
        }
      }

      &:last-child {
        a {
          margin-right: 0;
        }
      }

      &:first-child {
        a {
          margin-left: 0;
        }
      }

      &:hover,
      &.active {
        a {
          color: yellow;
          transition: $transition;
        }
      }
    }
  }

  .others-option {
    .option-item {
      color: $black-color;
      display: inline-block;
      position: relative;
      line-height: 1;

      .search-btn {
        cursor: pointer;
        transition: $transition;
        color: $black-color;
        font-size: 20px;
        font-weight: 600;
      }

      .close-btn {
        cursor: pointer;
        display: none;
        transition: $transition;
        width: 20px;
        color: $black-color;
        font-size: 18px;

        &.active {
          display: block;
        }
      }
    }
  }
}

/*================================================
Banner Area CSS
=================================================*/
.main-banner {
  position: relative;
  height: 800px;
  overflow: hidden;
  z-index: 1;

  background: {
    position: center;
    size: cover;
    repeat: no-repeat;
    attachment: fixed;
  }

  .container-fluid {
    max-width: 1400px;
    margin: auto;
  }

  .banner-content {
    margin-top: -100px;

    h1 {
      font-size: 65px;
      color: $white-color;
      margin: 10px 0 10px 0;
      line-height: 1.3;
    }

    p {
      margin-bottom: 0;
      color: $white-color;
      line-height: 1.8;
      font-size: 18px;
    }

    .banner-holder {
      margin-top: 0px;

      a {
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .banner-btn {
      margin-top: 30px;
    }
  }

  .banner-image {
    text-align: right;
  }

  .banner-form {
    border-radius: 5px;
    background-color: $white-color;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.02);
    padding: 40px;

    h3 {
      margin-bottom: 25px;
      font-size: 25px;
      text-align: center;
    }

    form {
      .form-control {
        height: 50px;
        padding: 3px 20px;
        line-height: initial;
        color: $white-color;
        background-color: transparent;
        border: 1px solid #3e57c5;
        border-radius: 0;
        -webkit-box-shadow: unset !important;
        box-shadow: unset !important;
        -webkit-transition: 0.5s;
        transition: 0.5s;

        font: {
          size: 15px;
          weight: 400;
        }

        &:focus {
          border-color: $main-color;
        }
      }

      textarea.form-control {
        height: auto;
        padding-top: 15px;
        color: $white-color;
      }

      button {
        display: block;
        width: 100%;
        margin-top: 20px;
        color: $white-color;
        background-color: $main-color;
        padding: 15px 25px 12px;
        border: 1px solid $main-color;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        // text-transform: capitalize;
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;

        &:hover {
          border-color: $main-color;
          color: $main-color;
          background-color: transparent;
          box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.02);
        }
      }
    }
  }
}

/*================================================
Preloader Area CSS
=================================================*/
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white-color;
  z-index: 999999;

  .preloader {
    width: 100px;
    height: 100px;
    display: inline-block;
    padding: 0;
    box-sizing: border-box;
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translateX(-50%) translateY(-48%);

    span {
      position: absolute;
      display: inline-block;
      width: 100px;
      height: 100px;
      border-radius: 100%;
      background-color: $main-color !important;
      animation: preloader 1.3s linear infinite;

      &:last-child {
        animation-delay: -0.8s;
      }
    }
  }
}

@keyframes preloader {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@-webkit-keyframes preloader {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}

.post-navigation {
  margin-top: 30px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;

  padding: {
    top: 20px;
    bottom: 20px;
  }

  .navigation-links {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    .nav-previous {
      -ms-flex: 0 0 50%;
      -webkit-box-flex: 0;
      flex: 0 0 50%;
      max-width: 50%;

      a {
        i {
          margin-right: 2px;
          transition: $transition;
        }

        &:hover {
          i {
            margin-right: 0;
          }
        }
      }
    }

    .nav-next {
      -ms-flex: 0 0 50%;
      -webkit-box-flex: 0;
      flex: 0 0 50%;
      max-width: 50%;
      text-align: right;

      a {
        i {
          margin-left: 2px;
          transition: $transition;
        }

        &:hover {
          i {
            margin-left: 0;
          }
        }
      }
    }

    div {
      a {
        display: inline-block;
        font-weight: 700;
      }
    }
  }
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  top: 0;
  right: 15px;
  color: $white-color;
  background-color: $main-color;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  opacity: 0;
  visibility: hidden;
  transition: 0.9s;
  border-radius: 50%;
  border: 1px solid $main-color;

  &.active {
    top: 98%;
    transform: translateY(-98%);
    opacity: 1;
    visibility: visible;
  }

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: $transition;
    font-size: 16px;

    &:last-child {
      opacity: 0;
      visibility: hidden;
      top: 60%;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: $black-color;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
    border-radius: 50%;
  }

  &:hover,
  &:focus {
    color: $white-color;

    &::before {
      opacity: 1;
      visibility: visible;
    }

    i {
      &:first-child {
        opacity: 0;
        top: 0;
        visibility: hidden;
      }

      &:last-child {
        opacity: 1;
        visibility: visible;
        top: 50%;
      }
    }
  }
}


// .onhover-div {
//     padding-top: 20px;
//     padding-bottom: 20px;

//     >div {
//         img {
//             filter: brightness(54);
//         }
//     }
// }

.onhover-div {
  position: relative;

  // padding-top: 40px;
  // padding-bottom: 40px;
  // font-family: $font_0, $font_1;
  img,
  i {
    cursor: pointer;
  }

  .show-div {
    top: 150px;
    position: absolute;
    z-index: 9;
    background-color: $white;
    transition: all linear 0.3s;
    min-width: 236px;
    text-align: left;
    -webkit-box-shadow: 0 0 1px 0 #ebebeb;
    -moz-box-shadow: 0 0 1px 0 #ebebeb;
    box-shadow: 0 0 1px 0 #ebebeb;
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    right: 0;
    padding: 0;
    border-radius: 0 !important;
  }

  &:hover {
    .show-div {
      opacity: 1;
      transform: translateY(0px);
      visibility: visible;
    }
  }
}

.mobile-cart-content {
  display: none;
  justify-content: center;
  margin-top: 10px;

  .qty-box {
    border-right: 1px solid 5px;
    border-radius: 5px;

    .input-group {
      .form-control {
        width: 48px;
        padding: 6px;
      }
    }
  }

  .col {
    align-self: center;
    margin-left: 10px;
    margin-right: 10px;
  }

  h2 {
    font-size: 20px;
  }
}

.mobile-cart {
  i {
    display: none;
  }

  .close-circle {
    i {
      display: block;
    }
  }
}

.mobile-cart {

  .show-div {
    top: 65px;
    left: 0;

    &.shopping-cart {
      position: absolute;
      top: 50px;
      bottom: unset;
      left: -250px !important;
    }
  }

  ;

  right: 48%;
  top: 8px;
  margin-left: 30px;

  .close-circle {
    i {
      font-size: 14px;
    }
  }

  i {
    display: inline-block;
    font-size: 22px;
  }

  >div {
    img {
      display: block;
    }
  }
}


@media (max-width: 1000px) {

  .mobile-cart {
    right: 0px;
    top: 8px;
    margin-left: 0px;

    .show-div.shopping-cart {
      top: 50px;
      bottom: unset;
      left: -190px !important;
      border: brown 1px solid;
      padding: 10px !important;
    }
  }
}




.onhover-show-div {
  top: 100px;
  bottom: unset;
  left: 0px !important;
}

.cart_qty_cls {
  position: absolute;
  background: $paragraph-color;
  width: 20px;
  height: 20px;
  color: #fff;
  border-radius: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  top: 50%;
  right: -18px;
  padding: 3px 3px;
}

@media (max-width: 1000px) {
  .cart_qty_cls {
    right: 120px;
  }

  .navbar {
    &.is-sticky {
      .navbar-nav {
        .nav-item {
          a {
            color: #fff;
          }
        }
      }
    }
  }



}

form {
  .form-control {
    height: 50px;
    padding: 3px 20px;
    line-height: initial;
    color: $main-color;
    background-color: transparent;
    border: 1px solid rgb(198, 198, 198);
    border-radius: 0;
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
    -webkit-transition: 0.5s;
    transition: 0.5s;

    font: {
      size: 15px;
      weight: 400;
    }

    &:focus {
      border-color: $main-color;
    }
  }

  textarea.form-control {
    height: auto;
    padding-top: 15px;
    color: $black-color;
  }

  select.form-control {
    height: auto;
    padding-top: 15px;
    color: $black-color;
  }

  button.form-control {
    display: block;
    width: 100%;
    margin-top: 20px;
    color: $main-color;
    background-color: $main-color;
    padding: 15px 25px 12px;
    border: 1px solid $main-color;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    // text-transform: capitalize;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      border-color: $main-color;
      color: $main-color;
      background-color: transparent;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.02);
    }
  }

  select.custom-select {
    height: 50px;
    padding: 3px 0 0 15px;
    line-height: initial;
    color: $main-color;
    background-color: transparent;
    border: 1px solid $main-color;
    border-radius: 0;
  }
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}










/*-----------------------------------*\
  #style.css
\*-----------------------------------*/

/**
 * copyright 2022 codewithsadee
 */





/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {

  /**
     * colors
     */

  --bright-yellow-crayola: hsl(36, 94%, 57%);
  --portland-orange: hsl(15, 84%, 57%);
  --battleship-gray: hsl(0, 0%, 53%);
  --silver-chalice: hsl(0, 0%, 70%);
  --spanish-gray: hsl(0, 0%, 60%);
  --sonic-silver: hsl(208, 7%, 46%);
  --raisin-black: hsl(228, 6%, 17%);
  --eerie-black: hsl(210, 3%, 13%);
  --bittersweet: hsl(9, 96%, 69%);
  --light-gray: hsl(0, 0%, 80%);
  --platinum: hsl(0, 0%, 91%);
  --amber: hsl(45, 100%, 51%);
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --onyx: hsl(220, 2%, 24%);

  /**
     * typography
     */

  --ff-bangers: 'Bangers', cursive;
  --ff-carter_one: 'Carter One', cursive;
  --ff-nunito_sans: 'Nunito Sans', sans-serif;

  --fs-1: 6.5rem;
  --fs-2: 3.2rem;
  --fs-3: 2.4rem;
  --fs-4: 2rem;
  --fs-5: 1.8rem;
  --fs-6: 1.5rem;
  --fs-7: 1.4rem;
  --fs-8: 1rem;

  --fw-400: 400;
  --fw-700: 700;

  /**
     * spacing
     */

  --section-padding: 40px;

  /**
     * shadow
     */

  --shadow-1: 0 8px 16px hsla(0, 0%, 0%, 0.15);
  --shadow-2: 0 8px 8px hsla(0, 0%, 0%, 0.2);

  /**
     * radius
     */

  --radius-4: 4px;
  --radius-10: 10px;

  /**
     * transition
     */

  --transition-1: 0.25s ease;
  --transition-2: 0.5s ease;
  --cubic-in: cubic-bezier(0.51, 0.03, 0.64, 0.28);
  --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);

}





/*-----------------------------------*\
    #RESET
  \*-----------------------------------*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a,
img,
span,
button,
ion-icon {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  height: auto;
}

button {
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
}

ion-icon {
  pointer-events: none;
}

address {
  font-style: normal;
}

html {
  font-family: var(--ff-nunito_sans);
  font-size: 10px;
  scroll-behavior: smooth;
  background-color: #336ca8;
}

body {
  background-color: var(--white);
  color: var(--black);
  font-size: 1.6rem;
  line-height: 1.5;
}

:focus-visible {
  outline-offset: 4px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: hsl(0, 0%, 98%);
}

::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 80%);
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsl(0, 0%, 70%);
}





/*-----------------------------------*\
    #REUSED STYLE
  \*-----------------------------------*/

.container {
  padding-inline: 15px;
}

.section {
  // padding-block: var(--section-padding);
}

.has-bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.h1,
.h2,
.h3 {
  line-height: 1.1;
}

.h1 {
  color: var(--white);
  font-family: var(--ff-bangers);
  font-size: var(--fs-1);
  font-weight: var(--fw-400);
  letter-spacing: 1px;
}

.h2,
.h3 {
  color: var(--eerie-black);
}

.h2 {
  font-size: var(--fs-2);
}

.h3 {
  font-size: var(--fs-3);
}

.btn {
  background-color: #153c7f;
  color: var(--white);
  padding: 10px;
  font-weight: var(--fw-700);
  border-radius: 50px;
  transition: var(--transition-1);
  height: 50px;
  width: 202px;
  font-size: 20px;
}

.section-title {
  text-align: center;
  margin-block-end: 30px;
}

.section-title .span {
  display: inline;
  color: var(--portland-orange);
}

.text-especial {
  color: var(--portland-orange);
  font-weight: 900;
  text-align: center;
  font-size: 28px;
}

.text-para {
  font-size: 18px;
  font-style: italic;
  color: #153c7f;
}

.fechaBlog {
  font-size: 18px;
  color: hsl(0, 0%, 53%)
}


.img-holder {
  aspect-ratio: var(--width) / var(--height);
  background-color: var(--light-gray);
  overflow: hidden;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.has-scrollbar {
  display: flex;
  align-items: center;
  gap: 30px;
  overflow-x: auto;
  scroll-snap-type: inline mandatory;
  padding-block-end: 20px;
  margin-block-end: -20px;
}

.scrollbar-item {
  min-width: 100%;
  scroll-snap-align: start;
}

.has-scrollbar::-webkit-scrollbar {
  height: 10px;
}

.has-scrollbar::-webkit-scrollbar-track {
  outline: 2px solid var(--portland-orange);
  border-radius: var(--radius-10);
}

.has-scrollbar::-webkit-scrollbar-thumb {
  border-radius: var(--radius-10);
  background-color: var(--portland-orange);
  border: 2px solid var(--white);
}

.has-scrollbar::-webkit-scrollbar-button {
  width: calc(25% - 30px);
}

.grid-list {
  display: grid;
  gap: 30px;
}

.w-100 {
  width: 100%;
}





/*-----------------------------------*\
    #HEADER
  \*-----------------------------------*/

.action-btn.user {
  display: none;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--white);
  padding-block: 20px;
  z-index: 4;
}

.header.active {
  position: fixed;
  box-shadow: var(--shadow-1);
}

.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.nav-toggle-btn {
  font-size: 30px;
  transition: var(--transition-1);
}

.nav-toggle-btn.active .menu-icon,
.nav-toggle-btn .close-icon {
  display: none;
}

.nav-toggle-btn .menu-icon,
.nav-toggle-btn.active .close-icon {
  display: block;
}

.logo {
  font-family: var(--ff-carter_one);
  font-size: 3rem;
}

.header-actions {
  display: flex;
  gap: 15px;
}

.header .action-btn {
  position: relative;
  font-size: 22px;
  transition: var(--transition-1);
}

.header .btn-badge {
  position: absolute;
  top: -8px;
  right: -10px;
  background-color: var(--portland-orange);
  color: var(--white);
  font-size: var(--fs-8);
  min-width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 50px;
}

.nav-toggle-btn:is(:hover, :focus),
.header .action-btn:is(:hover, :focus) {
  transform: scale(1.1);
}

.navbar {
  position: fixed;
  // top: 85px;
  // left: -320px;
  // bottom: 0;
  // background-color: var(--white);
  // max-width: 320px;
  width: 100%;
  padding: 20px 10px;
  box-shadow: var(--shadow-2);
  transition: 0.25s var(--cubic-out);
  overflow-x: hidden;
  overflow-y: auto;
}

.navbar.active {
  transform: translateX(320px);
  transition-timing-function: var(--cubic-in);
}

.navbar-link {
  color: var(--eerie-black);
  font-size: var(--fs-6);
  padding: 10px 15px;
}

.navbar-action-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background-color: var(--sonic-silver);
  color: var(--white);
  font-weight: var(--fw-700);
  text-transform: uppercase;
  text-align: center;
}





/*-----------------------------------*\
    #HERO
  \*-----------------------------------*/

.hero {
  // margin-block-start: 85px;
  min-height: 360px;
  background-position: left;
  text-align: center;
}

.hero-title .span {
  font-size: 0.5em;
}

.hero-text {
  color: var(--black);
  margin-block: 15px 25px;
}

.hero .btn {
  margin-inline: auto;
}

.hero .btn:is(:hover, :focus) {
  background-color: var(--portland-orange);
}





/*-----------------------------------*\
    #CATEGORY
  \*-----------------------------------*/

.category-card .card-banner {
  border-radius: var(--radius-10);
}

.category-card .img-cover {
  transition: var(--transition-2);
}

.category-card:is(:hover, :focus-within) .img-cover {
  transform: scale(1.08);
}

.category-card .card-title {
  margin-block-start: 15px;
  text-align: center;
  transition: var(--transition-1);
}

.category-card .card-title:is(:hover, :focus) {
  color: var(--portland-orange);
}





/*-----------------------------------*\
    #OFFERS
  \*-----------------------------------*/

.offer-card {
  border-radius: var(--radius-10);
  padding: 20px;
  display: grid;
  align-content: center;
}

.offer-card .card-subtitle {
  font-size: var(--fs-7);
  text-transform: uppercase;
  font-weight: var(--fw-700);
}

.offer-card .card-title {
  color: var(--black);
  margin-block: 5px 24px;
}

.offer-card .btn {
  background-color: var(--portland-orange);
}

.offer-card .btn:is(:hover, :focus) {
  background-color: var(--black);
}





/*-----------------------------------*\
    #PRODUCT
  \*-----------------------------------*/

.product .img-cover.hover,
.product-card .card-action-btn {
  display: none;
}

.product-card {
  text-align: center;
}

.product-card .card-banner {
  border: 1px solid var(--platinum);
  border-radius: var(--radius-10);
  margin-block-end: 20px;
}

.product-card :is(.wrapper, .rating-wrapper) {
  display: flex;
}

.product-card .wrapper {
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: var(--silver-chalice);
  font-size: var(--fs-7);
}

.product-card .rating-wrapper {
  color: var(--amber);
  gap: 2px;
}

.product-card .rating-wrapper.gray {
  color: unset;
}

.product-card .h3 {
  --fs-3: 1.8rem;
  margin-block: 8px 10px;
}

.product-card .card-title {
  transition: var(--transition-1);
}

.product-card .card-title:is(:hover, :focus) {
  color: var(--portland-orange);
}

.product-card .card-price {
  color: var(--portland-orange);
  font-size: var(--fs-4);
  font-weight: var(--fw-700);
}





/*-----------------------------------*\
    #SERVICE
  \*-----------------------------------*/

.service .img {
  margin-inline: auto;
  margin-block-end: 30px;
}

.service .section-title {
  margin-block-end: 60px;
}

.service-card {
  text-align: center;
}

.service-card .card-icon {
  max-width: max-content;
  margin-inline: auto;
}

.service-card .card-title {
  margin-block: 10px;
}

.service-card .card-text {
  color: var(--spanish-gray);
  font-size: 18px;
}





/*-----------------------------------*\
    #CTA
  \*-----------------------------------*/

.cta {
  background-position: 75%;
}

.cta-banner {
  display: none;
}

.cta-content {
  padding-block: 80px var(--section-padding);
}

.cta .section-title {
  text-align: left;
  margin-block: 10px 20px;
}

.cta .section-text {
  margin-block-end: 30px;
}

.cta .btn:is(:hover, :focus) {
  background-color: var(--white);
  color: var(--black);
}





/*-----------------------------------*\
    #BRAND
  \*-----------------------------------*/

.brand {
  --section-padding: 100px;
}

.brand .has-scrollbar {
  gap: 0;
}

.brand .scrollbar-item {
  min-width: 50%;
}

.brand .scrollbar-item:not(:last-child) {
  border-inline-end: 1px solid var(--platinum);
}

.brand-card {
  max-width: max-content;
  margin-inline: auto;
}





/*-----------------------------------*\
    #FOOTER
  \*-----------------------------------*/

.footer {
  background-color: var(--raisin-black);
  color: var(--battleship-gray);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
}

.footer-top {
  padding-block-start: 100px;
  border-block-end: 1px solid var(--onyx);
  padding-bottom: 50px;
}

.footer-top .container {
  display: grid;
  gap: 30px;
}

.footer .logo {
  color: var(--white);
  margin-block-end: 10px;
}

.footer-text {
  font-size: var(--fs-5);
  margin-block-end: 25px;
  color: #ffffff;
}

.footer-text .link {
  display: inline-block;
  color: var(--portland-orange);
}

.contact-item {
  margin-block-end: 15px;
  color: var(--white);
  font-weight: var(--fw-700);
  display: flex;
  align-items: center;
  gap: 15px;
}

.contact-item ion-icon {
  flex-shrink: 0;
  font-size: 22px;
  color: var(--portland-orange);
}

.social-list {
  display: flex;
  gap: 10px;
}

.social-link {
  background-color: var(--white);
  color: var(--black);
  padding: 12px;
  border-radius: 50%;
  transition: var(--transition-1);
}

.social-link:is(:hover, :focus) {
  background-color: var(--portland-orange);
  color: var(--white);
}

.footer-list-title {
  color: var(--white);
  font-size: var(--fs-5);
  font-weight: var(--fw-700);
  padding-block: 10px;
}

.footer-link {
  padding-block: 4px;
  transition: var(--transition-1);
}

.footer-link:is(:hover, :focus) {
  color: var(--portland-orange);
}

.footer-bottom {
  padding-block: 10px;
  background-color: var(--white);
}

.copyright {
  margin-block-end: 10px;
  text-align: center;
}

.copyright-link {
  display: inline-block;
}

.footer-bottom .img {
  width: 380px;
  height: auto;
  max-width: max-content;
  text-align: center;
  margin: auto;
}




/*-----------------------------------*\
    #BACK TO TOP
  \*-----------------------------------*/

.back-top-btn {
  position: fixed;
  bottom: 10px;
  right: 20px;
  background-color: var(--portland-orange);
  color: var(--white);
  font-size: 25px;
  padding: 10px;
  border-radius: 50%;
  box-shadow: var(--shadow-2);
  opacity: 0;
  visibility: hidden;
  transition: var(--transition-1);
  z-index: 4;
}

.back-top-btn.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(-10px);
}





/*-----------------------------------*\
    #MEDIA QUERIES
  \*-----------------------------------*/

/**
   * responsive for large than 575px screen
   */

@media (min-width: 575px) {

  /**
     * CUSTOM PROPERTY
     */

  :root {

    /**
       * typography
       */

    --fs-2: 4rem;
    --fs-3: 2.6rem;

  }



  /**
     * REUSED STYLE
     */

  .scrollbar-item {
    min-width: calc(50% - 15px);
  }

  .grid-list {
    grid-template-columns: 1fr 1fr;
  }



  /**
     * HERO
     */

  .hero {
    display: grid;
    // padding-inline-start: 20px;
    justify-content: flex-normal;
    align-items: center;
  }



  /**
     * OFFER
     */

  .offer .grid-list {
    grid-template-columns: 1fr;
  }



  /**
     * PRODUCT
     */

  .product-card .card-banner {
    position: relative;
  }

  .product-card .card-action-btn,
  .product-card .card-banner .hover {
    display: block;
    position: absolute;
  }

  .product-card .card-banner .hover {
    top: 0;
    left: 0;
  }

  .product-card:is(:hover, :focus-within) .default,
  .product-card .hover {
    opacity: 0;
  }

  .product-card .default,
  .product-card:is(:hover, :focus-within) .hover {
    opacity: 1;
  }

  .product-card .card-action-btn {
    top: 15px;
    right: 15px;
    color: var(--eerie-black);
    font-size: 20px;
    background-color: var(--white);
    border: 1px solid var(--platinum);
    padding: 12px;
    border-radius: 50%;
    transition: var(--transition-1);
    opacity: 0;
  }

  .product-card .card-action-btn:is(:hover, :focus) {
    background-color: var(--portland-orange);
    border-color: var(--portland-orange);
    color: var(--white);
  }

  .product-card:is(:hover, :focus-within) .card-action-btn {
    opacity: 1;
  }



  /**
     * CTA
     */

  .cta .img {
    width: 250px;
  }

  .cta .h2 {
    --fs-2: 3.2rem;
  }



  /**
     * BRAND
     */

  .brand .scrollbar-item {
    min-width: 25%;
  }



  /**
     * FOOTER
     */

  .footer-top .container {
    grid-template-columns: 1fr 1fr;
  }

}





/**
   * responsive for large than 768px screen
   */

@media (min-width: 768px) {

  /**
     * CUSTOM PROPERTY
     */

  :root {

    /**
       * typography
       */

    --fs-1: 9rem;

  }



  /**
     * REUSED STYLE
     */

  .scrollbar-item {
    min-width: calc(33.33% - 20px);
  }

  .grid-list {
    grid-template-columns: repeat(3, 1fr);
  }



  /**
     * HERO
     */

  .hero {
    // aspect-ratio: 1512 / 784;
    // padding-inline-start: 10%;
  }

  .hero-text {
    font-size: 1.9rem;
  }



  /**
     * OFFER
     */

  .offer .grid-list {
    grid-template-columns: repeat(3, 1fr);
  }



  /**
     * CTA
     */

  .cta-banner {
    display: block;
  }

  .cta .container {
    display: grid;
    grid-template-columns: 1fr 0.75fr;
    align-items: flex-end;
  }



  /**
     * FOOTER
     */

  .footer-bottom .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

}





/**
   * responsive for large than 992px screen
   */

@media (min-width: 992px) {

  /**
     * CUSTOM PROPERTY
     */

  :root {

    /**
       * typography
       */

    --fs-1: 12rem;
    --fs-3: 3rem;

    /**
       * spacing
       */

    --section-padding: 50px;

  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: column !important;
  }

  /**
     * REUSED STYLE
     */

  .container {
    padding-inline: 30px;
  }

  .scrollbar-item {
    min-width: calc(20% - 24px);
  }

  /**
     * HEADER
     */

  .nav-toggle-btn,
  .navbar-action-btn {
    display: none;
  }

  .header {
    --color: var(--white);
    background: none;
  }

  .header.active {
    --color: var(--black);
    background-color: var(--white);
  }

  .header .logo,
  .header .action-btn {
    color: var(--color);
  }

  .header .action-btn.user {
    display: block;
  }

  .logo {
    font-size: 3.8rem;
  }

  .navbar,
  .navbar.active {
    all: unset;
    margin-inline-end: auto;
  }

  .navbar-list {
    display: flex;
    gap: 10px;
  }

  .navbar-link {
    color: var(--color);
    --fs-6: 1.7rem;
    font-weight: var(--fw-700);
    transition: var(--transition-1);
  }

  .header.active .navbar-link:is(:hover, :focus) {
    color: var(--portland-orange);
  }



  /**
     * HERO
     */

  // .hero {
  //   margin-block-start: 0;
  // }



  /**
     * CATEGORY
     */

  .category-card .h3 {
    --fs-3: 2rem;
  }



  /**
     * SERVICE
     */

  .service .grid-list {
    grid-template-columns: repeat(4, 1fr);
  }

  .service-card .h3 {
    --fs-3: 2.4rem;
  }



  /**
     * BRAND
     */

  .brand .scrollbar-item {
    min-width: 20%;
  }



  /**
     * FOOTER
     */

  .footer-top .container {
    grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr;
  }

}





/**
   * responsive for large than 1200px screen
   */

@media (min-width: 1200px) {

  /**
     * CUSTOM PROPERTY
     */

  :root {

    /**
       * typography
       */

    --fs-1: 15rem;
    --fs-3: 3.2rem;

  }



  /**
     * REUSED STYLE
     */

  .grid-list {
    grid-template-columns: repeat(4, 1fr);
  }



  /**
     * CTA
     */

  .cta-content {
    padding-block: 100px;
  }



  /**
     * BRAND, FOOTER
     */

  :is(.brand, .footer) .container {
    max-width: 1200px;
    width: 100%;
    margin-inline: auto;
  }

  .footer {
    padding-block-start: 40px;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row !important;
    margin-right: 50px !important;
  }


}


.banner {
  width: 100%;
  height: 700px;
  background-color: #2d81e1;
  background: url('./assets/images/banner6.jpg') no-repeat top center/cover, linear-gradient(to bottom, rgba(78, 84, 200, 0.5), rgba(143, 148, 251, 0.5), white);
  text-align: center;
  color: #333;
}

// @keyframes wave {
//     0% {
//         clip-path: polygon(0 0, 100% 0, 100% 80%, 80% 100%, 20% 100%, 0 80%);
//     }
//     50% {
//         clip-path: polygon(0 0, 100% 0, 100% 70%, 70% 100%, 30% 100%, 0 70%);
//     }
//     100% {
//         clip-path: polygon(0 0, 100% 0, 100% 80%, 80% 100%, 20% 100%, 0 80%);
//     }
// }

// .banner {
//     animation: wave 3s infinite;
//     /* ... */
// }

// // CAFETO-DORADO

// /* You can add global styles to this file, and also import other style files */
// /* You can add global styles to this file, and also import other style files */

// @import 'ngx-toastr/toastr';

// @import "./assets/scss/style.scss";

// $main-color: rgb(116, 79, 39);
// $white-color: #ffffff;
// $black-color: #000000;
// $paragraph-color: #646464;
// $font-size: 16px;
// $transition: 0.5s;

// body {
//     overflow-x: hidden;
// }


// /*================================================
// Navbar Area CSS
// =================================================*/
// .navbar {
//     position: relative;
//     padding-top: 20px;
//     padding-bottom: 20px;
//     font-size: 20px;
//     letter-spacing: 1px;

//     &.is-sticky {
//         position: fixed;
//         top: 0;
//         left: 0;
//         width: 100%;
//         z-index: 999;
//         box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
//         background-color: #653c0d !important;
//         animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
//         border-bottom: #653c0d 1px solid;

//         .navbar-nav {
//             margin: auto;

//             .nav-item {
//                 a {
//                     color: $white-color;
//                 }
//             }
//         }
//     }

//     .container-fluid {
//         max-width: 1400px;
//         margin: auto;
//     }
// }

// .navbar-light {
//     background-color: #0c2b5b !important;
//     color: $white-color;

//     .logo {
//         h3 {
//             font-size: 35px;
//             margin-bottom: 0;
//         }
//     }

//     .navbar-nav {
//         margin: auto;

//         .nav-item {
//             position: relative;
//             padding: 0;
//             margin-right: 10px;

//             span {
//                 display: block;
//                 font-size: 12px;
//             }

//             a {
//                 color: $white-color;
//                 // text-transform: capitalize;
//                 position: relative;

//                 font: {
//                     weight: 600;
//                     size: 20px;
//                 }

//                 padding: {
//                     left: 0;
//                     right: 0;
//                     top: 0;
//                     bottom: 0;
//                 }

//                 margin: {
//                     left: 14px;
//                     right: 24px;
//                 }

//                 &:hover,
//                 &:focus,
//                 &.active {
//                     color: $main-color;
//                     position: relative;
//                     transition: $transition;
//                 }

//                 i {
//                     font-size: 16px;
//                     position: relative;
//                     top: 2px;
//                     display: inline-block;
//                     margin-left: -4px;
//                     margin-right: -4px;
//                 }
//             }

//             &:last-child {
//                 a {
//                     margin-right: 0;
//                 }
//             }

//             &:first-child {
//                 a {
//                     margin-left: 0;
//                 }
//             }

//             &:hover,
//             &.active {
//                 a {
//                     color: yellow;
//                     transition: $transition;
//                 }
//             }
//         }
//     }

//     .others-option {
//         .option-item {
//             color: $black-color;
//             display: inline-block;
//             position: relative;
//             line-height: 1;

//             .search-btn {
//                 cursor: pointer;
//                 transition: $transition;
//                 color: $black-color;
//                 font-size: 20px;
//                 font-weight: 600;
//             }

//             .close-btn {
//                 cursor: pointer;
//                 display: none;
//                 transition: $transition;
//                 width: 20px;
//                 color: $black-color;
//                 font-size: 18px;

//                 &.active {
//                     display: block;
//                 }
//             }
//         }
//     }
// }

// /*================================================
// Banner Area CSS
// =================================================*/
// .main-banner {
//     position: relative;
//     height: 800px;
//     overflow: hidden;
//     z-index: 1;

//     background: {
//         position: center;
//         size: cover;
//         repeat: no-repeat;
//         attachment: fixed;
//     }

//     .container-fluid {
//         max-width: 1400px;
//         margin: auto;
//     }

//     .banner-content {
//         margin-top: -100px;

//         h1 {
//             font-size: 65px;
//             color: $white-color;
//             margin: 10px 0 10px 0;
//             line-height: 1.3;
//         }

//         p {
//             margin-bottom: 0;
//             color: $white-color;
//             line-height: 1.8;
//             font-size: 18px;
//         }

//         .banner-holder {
//             margin-top: 0px;

//             a {
//                 margin-right: 15px;

//                 &:last-child {
//                     margin-right: 0;
//                 }
//             }
//         }

//         .banner-btn {
//             margin-top: 30px;
//         }
//     }

//     .banner-image {
//         text-align: right;
//     }

//     .banner-form {
//         border-radius: 5px;
//         background-color: $white-color;
//         box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.02);
//         padding: 40px;

//         h3 {
//             margin-bottom: 25px;
//             font-size: 25px;
//             text-align: center;
//         }

//         form {
//             .form-control {
//                 height: 50px;
//                 padding: 3px 0 0 15px;
//                 line-height: initial;
//                 color: $white-color;
//                 background-color: transparent;
//                 border: 1px solid #3e57c5;
//                 border-radius: 0;
//                 -webkit-box-shadow: unset !important;
//                 box-shadow: unset !important;
//                 -webkit-transition: 0.5s;
//                 transition: 0.5s;

//                 font: {
//                     size: 15px;
//                     weight: 400;
//                 }

//                 &:focus {
//                     border-color: $main-color;
//                 }
//             }

//             textarea.form-control {
//                 height: auto;
//                 padding-top: 15px;
//                 color: $white-color;
//             }

//             button {
//                 display: block;
//                 width: 100%;
//                 margin-top: 20px;
//                 color: $white-color;
//                 background-color: $main-color;
//                 padding: 15px 25px 12px;
//                 border: 1px solid $main-color;
//                 -webkit-transition: 0.5s;
//                 transition: 0.5s;
//                 // text-transform: capitalize;
//                 font-size: 15px;
//                 font-weight: 500;
//                 cursor: pointer;

//                 &:hover {
//                     border-color: $main-color;
//                     color: $main-color;
//                     background-color: transparent;
//                     box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.02);
//                 }
//             }
//         }
//     }
// }

// /*================================================
// Preloader Area CSS
// =================================================*/
// .preloader {
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: $white-color;
//     z-index: 999999;

//     .preloader {
//         width: 100px;
//         height: 100px;
//         display: inline-block;
//         padding: 0;
//         box-sizing: border-box;
//         position: absolute;
//         top: 48%;
//         left: 50%;
//         transform: translateX(-50%) translateY(-48%);

//         span {
//             position: absolute;
//             display: inline-block;
//             width: 100px;
//             height: 100px;
//             border-radius: 100%;
//             background-color: $main-color !important;
//             animation: preloader 1.3s linear infinite;

//             &:last-child {
//                 animation-delay: -0.8s;
//             }
//         }
//     }
// }

// @keyframes preloader {
//     0% {
//         transform: scale(0, 0);
//         opacity: 0.5;
//     }

//     100% {
//         transform: scale(1, 1);
//         opacity: 0;
//     }
// }

// @-webkit-keyframes preloader {
//     0% {
//         -webkit-transform: scale(0, 0);
//         opacity: 0.5;
//     }

//     100% {
//         -webkit-transform: scale(1, 1);
//         opacity: 0;
//     }
// }

// .post-navigation {
//     margin-top: 30px;
//     border-top: 1px solid #eeeeee;
//     border-bottom: 1px solid #eeeeee;

//     padding: {
//         top: 20px;
//         bottom: 20px;
//     }

//     .navigation-links {
//         display: -ms-flexbox;
//         display: -webkit-box;
//         display: flex;
//         -ms-flex-wrap: wrap;
//         flex-wrap: wrap;

//         .nav-previous {
//             -ms-flex: 0 0 50%;
//             -webkit-box-flex: 0;
//             flex: 0 0 50%;
//             max-width: 50%;

//             a {
//                 i {
//                     margin-right: 2px;
//                     transition: $transition;
//                 }

//                 &:hover {
//                     i {
//                         margin-right: 0;
//                     }
//                 }
//             }
//         }

//         .nav-next {
//             -ms-flex: 0 0 50%;
//             -webkit-box-flex: 0;
//             flex: 0 0 50%;
//             max-width: 50%;
//             text-align: right;

//             a {
//                 i {
//                     margin-left: 2px;
//                     transition: $transition;
//                 }

//                 &:hover {
//                     i {
//                         margin-left: 0;
//                     }
//                 }
//             }
//         }

//         div {
//             a {
//                 display: inline-block;
//                 font-weight: 700;
//             }
//         }
//     }
// }

// /*================================================
// Go Top CSS
// =================================================*/
// .go-top {
//     position: fixed;
//     cursor: pointer;
//     top: 0;
//     right: 15px;
//     color: $white-color;
//     background-color: $main-color;
//     z-index: 4;
//     width: 40px;
//     text-align: center;
//     height: 40px;
//     line-height: 40px;
//     opacity: 0;
//     visibility: hidden;
//     transition: 0.9s;
//     border-radius: 50%;
//     border: 1px solid $main-color;

//     &.active {
//         top: 98%;
//         transform: translateY(-98%);
//         opacity: 1;
//         visibility: visible;
//     }

//     i {
//         position: absolute;
//         top: 50%;
//         transform: translateY(-50%);
//         left: 0;
//         right: 0;
//         margin: 0 auto;
//         transition: $transition;
//         font-size: 16px;

//         &:last-child {
//             opacity: 0;
//             visibility: hidden;
//             top: 60%;
//         }
//     }

//     &::before {
//         content: "";
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         z-index: -1;
//         background: $black-color;
//         opacity: 0;
//         visibility: hidden;
//         transition: $transition;
//         border-radius: 50%;
//     }

//     &:hover,
//     &:focus {
//         color: $white-color;

//         &::before {
//             opacity: 1;
//             visibility: visible;
//         }

//         i {
//             &:first-child {
//                 opacity: 0;
//                 top: 0;
//                 visibility: hidden;
//             }

//             &:last-child {
//                 opacity: 1;
//                 visibility: visible;
//                 top: 50%;
//             }
//         }
//     }
// }


// // .onhover-div {
// //     padding-top: 20px;
// //     padding-bottom: 20px;

// //     >div {
// //         img {
// //             filter: brightness(54);
// //         }
// //     }
// // }

// .onhover-div {
//     position: relative;

//     // padding-top: 40px;
//     // padding-bottom: 40px;
//     // font-family: $font_0, $font_1;
//     img,
//     i {
//         cursor: pointer;
//     }

//     .show-div {
//         top: 150px;
//         position: absolute;
//         z-index: 9;
//         background-color: $white;
//         transition: all linear 0.3s;
//         min-width: 236px;
//         text-align: left;
//         -webkit-box-shadow: 0 0 1px 0 #ebebeb;
//         -moz-box-shadow: 0 0 1px 0 #ebebeb;
//         box-shadow: 0 0 1px 0 #ebebeb;
//         transform: translateY(30px);
//         opacity: 0;
//         visibility: hidden;
//         right: 0;
//         padding: 0;
//         border-radius: 0 !important;
//     }

//     &:hover {
//         .show-div {
//             opacity: 1;
//             transform: translateY(0px);
//             visibility: visible;
//         }
//     }
// }

// .mobile-cart-content {
//     display: none;
//     justify-content: center;
//     margin-top: 10px;

//     .qty-box {
//         border-right: 1px solid 5px;
//         border-radius: 5px;

//         .input-group {
//             .form-control {
//                 width: 48px;
//                 padding: 6px;
//             }
//         }
//     }

//     .col {
//         align-self: center;
//         margin-left: 10px;
//         margin-right: 10px;
//     }

//     h2 {
//         font-size: 20px;
//     }
// }

// .mobile-cart {
//     i {
//         display: none;
//     }

//     .close-circle {
//         i {
//             display: block;
//         }
//     }
// }

// .mobile-cart {

//     .show-div {
//         top: 65px;
//         left: 0;

//         &.shopping-cart {
//             top: 50px;
//             bottom: unset;
//             left: -250px !important;
//         }
//     }

//     ;

//     right: 48%;
//     top: 8px;
//     margin-left: 30px;

//     .close-circle {
//         i {
//             font-size: 14px;
//         }
//     }

//     i {
//         display: inline-block;
//         font-size: 22px;
//     }

//     >div {
//         img {
//             display: block;
//         }
//     }
// }


// @media (max-width: 1000px) {

//     .mobile-cart {
//         right: 0px;
//         top: 8px;
//         margin-left: 0px;

//         .show-div.shopping-cart {
//             top: 50px;
//             bottom: unset;
//             left: -190px !important;
//             border: brown 1px solid;
//             padding: 10px !important;
//         }
//     }
// }




// .onhover-show-div {
//     top: 100px;
//     bottom: unset;
//     left: 0px !important;
// }

// .cart_qty_cls {
//     position: absolute;
//     background: $paragraph-color;
//     width: 20px;
//     height: 20px;
//     color: #fff;
//     border-radius: 20px;
//     text-align: center;
//     font-size: 12px;
//     line-height: 14px;
//     font-weight: 600;
//     top: 50%;
//     right: -18px;
//     padding: 3px 3px;
// }

// @media (max-width: 1000px) {
//     .cart_qty_cls {
//         right: 120px;
//     }

//     .navbar {
//         &.is-sticky {
//             .navbar-nav {
//                 .nav-item {
//                     a {
//                         color: $main-color;
//                     }
//                 }
//             }
//         }
//     }



// }

// form {
//     .form-control {
//         height: 50px;
//         padding: 3px 0 0 15px;
//         line-height: initial;
//         color: $main-color;
//         background-color: transparent;
//         border: 1px solid rgb(198, 198, 198);
//         border-radius: 0;
//         -webkit-box-shadow: unset !important;
//         box-shadow: unset !important;
//         -webkit-transition: 0.5s;
//         transition: 0.5s;

//         font: {
//             size: 15px;
//             weight: 400;
//         }

//         &:focus {
//             border-color: $main-color;
//         }
//     }

//     textarea.form-control {
//         height: auto;
//         padding-top: 15px;
//         color: $black-color;
//     }

//     select.form-control {
//         height: auto;
//         padding-top: 15px;
//         color: $black-color;
//     }

//     button.form-control {
//         display: block;
//         width: 100%;
//         margin-top: 20px;
//         color: $main-color;
//         background-color: $main-color;
//         padding: 15px 25px 12px;
//         border: 1px solid $main-color;
//         -webkit-transition: 0.5s;
//         transition: 0.5s;
//         // text-transform: capitalize;
//         font-size: 15px;
//         font-weight: 500;
//         cursor: pointer;

//         &:hover {
//             border-color: $main-color;
//             color: $main-color;
//             background-color: transparent;
//             box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.02);
//         }
//     }

//     select.custom-select {
//         height: 50px;
//         padding: 3px 0 0 15px;
//         line-height: initial;
//         color: $main-color;
//         background-color: transparent;
//         border: 1px solid $main-color;
//         border-radius: 0;
//     }
// }

// .media {
//     display: -ms-flexbox;
//     display: flex;
//     -ms-flex-align: start;
//     align-items: flex-start;
// }

// .media-body {
//     -ms-flex: 1;
//     flex: 1;
// }
